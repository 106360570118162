@font-face {
  font-family: "SVN-Poppins";
  src: url("../fonts/SVN-PoppinsExtraLight.woff2") format("woff2"),
    url("../fonts/SVN-PoppinsExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SVN-Poppins";
  src: url("../fonts/SVN-PoppinsBold.woff2") format("woff2"),
    url("../fonts/SVN-PoppinsBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SVN-Poppins";
  src: url("../fonts/SVN-PoppinsMedium.woff2") format("woff2"),
    url("../fonts/SVN-PoppinsMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SVN-Poppins";
  src: url("../fonts/SVN-Poppins.woff2") format("woff2"),
    url("../fonts/SVN-Poppins.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SVN-Poppins";
  src: url("../fonts/SVN-PoppinsSemiBold.woff2") format("woff2"),
    url("../fonts/SVN-PoppinsSemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
